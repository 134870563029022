
import { mapActions, mapMutations, mapGetters } from "vuex";
import yaCaptchaValidate from "@/mixins/ya-captcha-validate.js";
import formValidation from "@/mixins/formValidation";

export default {
  data() {
    return {
      isSubmitting: false
    };
  },
  mixins: [formValidation, yaCaptchaValidate],
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings",
      captchaToken: 'captcha/captcha/captchaToken',
    })
  },
  props: {
    offer: Object
  },
  methods: {
    ...mapMutations({
      setModalCount: "modal/modal-main/SET_OPEN_MODAL_COUNT"
    }),
    ...mapActions({
      sendForm: "form/form/sendForm",
      closeModal: "modal/modal-main/closeModal"
    }),
    checkForm() {
      if (this.form.name.value.length < 2) {
        this.form.name.valid = false;
        return false;
      }
      if (!this.form.phone.valid) {
        this.form.phone.valid = false;
        return false;
      }
      if (!this.form.agree) {
        this.error = "agree";
        return false;
      }
      if (this.captchaToken === null) {
        this.error = 'captcha';
        return false
      }
      return true;
    },
    async submitForm() {
      if (this.isSubmitting) return;

      if(this.captchaToken === null) 
				this.renderCaptcha({
					smartCaptcha: window.smartCaptcha,
					container: 'captcha-container-footer',
					callback: () => {
						this.submitForm();
					}
				});

      if (this.checkForm()) {
        try {
          this.isSubmitting = true;
          let formData = {
            chosen_car: {},
            type: "callback",
            client_name: this.form.name.value,
            client_phone: this.form.phone.value,
            smart_captcha_token: this.captchaToken,
          };
          // utm
          if (localStorage.utm_source) {
            formData.utm_source = localStorage.utm_source;
          }
          if (localStorage.utm_medium) {
            formData.utm_medium = localStorage.utm_medium;
          }
          if (localStorage.utm_campaign) {
            formData.utm_campaign = localStorage.utm_campaign;
          }
          if (localStorage.utm_term) {
            formData.utm_term = localStorage.utm_term;
          }
          if (localStorage.utm_content) {
            formData.utm_content = localStorage.utm_content;
          }

          await this.sendForm(formData);

          this.form.name.value = "";
          this.form.phone.value = "";
        } catch (error) {
          console.error("Ошибка при отправке формы:", error);
        } finally {
          this.isSubmitting = false;
        }
      }
    }
  },
};
