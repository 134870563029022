
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			modalComponentMemory: '',
			modalTitleMemory: '',
			modalSubTitleMemory: '',
			touchStartY: 0,
			touchEndY: 0,
			isClosing: false,
		}
	},
	computed: {
		...mapGetters({
			modalTitle: 'modal/modal-main/modalTitle',
			modalSubTitle: 'modal/modal-main/modalSubTitle',
			modalComponent: 'modal/modal-main/modalComponent',
			modalPopUp: 'modal/modal-main/modalPopUp',
		}),
		withoutOverlay() {
			return this.modalComponentMemory === 'modal-callback-main' 
			|| this.modalComponentMemory === 'modal-vin'
			|| this.modalComponentMemory === 'modal-popup'
		}
	},
	methods: {
		...mapActions({
			closeModal: 'modal/modal-main/closeModal'
		}),
		handleTouchStart(event) {
			if (!this.modalPopUp) return;
			this.touchStartY = event.touches[0].clientY;
		},
		handleTouchMove(event) {
			if (!this.modalPopUp) return;
			this.touchEndY = event.touches[0].clientY;
		},
		handleTouchEnd() {
			if (!this.modalPopUp) return;
			const deltaY = this.touchEndY - this.touchStartY;
			if (deltaY > 50) {
				this.closeModalWithAnimation();
			}
		},
		closeModalWithAnimation() {
			this.isClosing = true;
			if(this.modalPopUp)
				setTimeout(() => {
					this.closeModal();
				}, 300);
			else
				this.closeModal();
		},
	},
	mounted() {
		if (this.modalComponent) this.modalComponentMemory = this.modalComponent
		if (this.modalTitle) this.modalTitleMemory = this.modalTitle
		if (this.modalSubTitle) this.modalSubTitleMemory = this.modalSubTitle
	}
}
