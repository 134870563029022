import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=369f4b69&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFooter: require('/home/d/didisher2/first-autosalon.ru/www/components/form/form-footer.vue').default,Footer: require('/home/d/didisher2/first-autosalon.ru/www/components/Footer/footer.vue').default})
